var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ms-teams-selector alt-comp-wrapper"},[_c('v-autocomplete-alt',{attrs:{"auto-select-first":"","label":_vm.label == null || _vm.label == ''
        ? ("<img src='" + _vm.defaultTeamsLogo + "' style='height: 14px;' class='mr-2'> MS Team")
        : _vm.label,"id":"mircosoftTeams","placeholder":"Select a Team","items":_vm.msTeams,"filter":_vm.msTeamsFilter,"dense":"","filled":"","item-value":"id","item-text":"name","clearable":"","loading":_vm.isMSTeamsLoading,"disabled":_vm.isMSTeamsLoading,"readonly":_vm.readonly},on:{"input":_vm.handleSelectedMSTeam},scopedSlots:_vm._u([_vm._l((['selection', 'item']),function(item){return {key:("" + item),fn:function(data){return [_c('v-row',{key:'teamSelector_' + item.id,staticClass:"py-0",staticStyle:{"flex":"none","max-width":"calc(100% - 40px)","flex-wrap":"nowrap"},attrs:{"align-content":"center","justify":"start","no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center mr-2",attrs:{"sm":"auto"}},[_c('v-img',{attrs:{"src":_vm.defaultTeamsLogo,"height":"28","width":"28","position":"center center","contain":""}})],1),_c('v-col',{staticClass:"d-flex justify-center col-auto flex-column pl-2"},[_c('div',{staticClass:"ms-teams-top"},[_c('div',{staticClass:"ms-teams-name mr-2"},[_vm._v(" "+_vm._s(data.item.name)+" ")]),_c('ms-team-visibility',{attrs:{"small":"","visibility":data.item.visibility}})],1),_c('div',{staticClass:"ms-teams-desc",staticStyle:{"max-width":"100%"}},[_vm._v(" "+_vm._s(data.item.description)+" ")])])],1)]}}}),{key:"label-append",fn:function(){return [_vm._t("label-append")]},proxy:true}],null,true),model:{value:(_vm.selected.teamId),callback:function ($$v) {_vm.$set(_vm.selected, "teamId", $$v)},expression:"selected.teamId"}}),_c('v-tooltip',{attrs:{"top":"","nudge-top":"-4","disabled":_vm.teamMainLink == null},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0",attrs:{"min-width":"28px !important","width":"28px !important","height":"28px !important","color":"secondary","outlined":"","small":"","target":"_blank","href":_vm.teamMainLink,"loading":_vm.isTeamInfoLoading,"disabled":_vm.teamMainLink == null}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fad fa-arrow-up-right-from-square fs-14px"})])]}}])},[_c('span',[_vm._v("Open in Microsoft Teams App")])]),_c('v-tooltip',{attrs:{"top":"","nudge-top":"-4","disabled":_vm.sharepointUrl == null},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0",attrs:{"min-width":"28px !important","width":"28px !important","height":"28px !important","color":"secondary","outlined":"","small":"","target":"_blank","href":_vm.sharepointUrl,"loading":_vm.isTeamInfoLoading,"disabled":_vm.sharepointUrl == null}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fad fa-folder-tree fs-14px"})])]}}])},[_c('span',[_vm._v("Open SharePoint Folder")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }