export default [
  {
    text: "Actions",
    value: "actions",
    align: "left",
    sortable: false,
    width: "64px",
    order: 0,
    hidable: true,
    hidden: false,
  },
  // {
  //   text: "Jira",
  //   icon: "fab fa-jira mr-1",
  //   value: "jiraCode",
  //   width: "110px",
  //   order: 1,
  //   hidable: false,
  //   hidden: false,
  // },
  { text: "Client", value: "client", width: "64px", order: 2, hidable: false, hidden: false },
  { text: "#", value: "number", width: "72px", order: 3, hidable: false, hidden: false },
  { text: "Name", value: "name", width: "150px", order: 4, hidable: false, hidden: false },
  { text: "Status", value: "status", width: "150px", order: 5, hidable: true, hidden: false },
  // {
  //   text: "Labor Budget",
  //   value: "laborBudget",
  //   sortable: false,
  //   width: "120px",
  //   order: 6,
  //   hidable: true,
  //   hidden: true,
  //   type: "usd",
  // },
  // {
  //   text: "Materials Budget",
  //   value: "materialsBudget",
  //   sortable: false,
  //   width: "135px",
  //   order: 7,
  //   hidable: true,
  //   hidden: true,
  //   type: "usd",
  // },
  // {
  //   text: "Total Budget",
  //   value: "totalBudget",
  //   sortable: false,
  //   width: "120px",
  //   order: 8,
  //   hidable: true,
  //   hidden: false,
  //   type: "usd",
  // },
  {
    text: "Start Date",
    value: "potentialStartDate",
    width: "110px",
    order: 6,
    hidable: true,
    hidden: false,
    type: "date",
  },
  {
    text: "Construction Date",
    value: "potentialConstructionStartDate",
    width: "160px",
    order: 7,
    hidable: true,
    hidden: false,
    type: "date",
  },
  {
    text: "Complete Date",
    value: "potentialConstructionCompleteDate",
    width: "135px",
    order: 8,
    hidable: true,
    hidden: false,
    type: "date",
  },
  {
    text: "Created By",
    value: "createdBy",
    order: 9,
    hidable: true,
    hidden: false,
    type: "user",
  },
  {
    text: "Updated By",
    value: "updatedBy",
    order: 10,
    hidable: true,
    hidden: false,
    type: "user",
  },
  {
    text: "Created",
    value: "createDate",
    width: "110px",
    order: 11,
    hidable: true,
    hidden: false,
    type: "date",
  },
  {
    text: "Last Updated",
    value: "updateDate",
    width: "130px",
    order: 12,
    hidable: true,
    hidden: false,
    type: "date",
  },
];
